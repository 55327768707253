html {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./logo.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#infoForm {
	margin-top: -20px;
}

#form-contents {
	margin-left: 30%;
	display: inline-block;
	font-family: 'Open Sans';
}

#payment-form {
	width: 70%;
	margin: 5px auto 0px auto;
}

#card-button {
	background-color: rgb(146, 235, 101, 0.95);
	padding: 5px 10px 0px 10px;
	border-radius: 10px;
	outline: 0;
	box-shadow: 0px 2px 2px rgb(64, 60, 45);
	text-transform: uppercase;
	margin-bottom: 10px;
	cursor: pointer;
	transition: ease background-color 400ms;
	font-size: 18px;
	font-family: Comfortaa, cursive;
	margin-left: 50%;
}

#card-button:hover {
	background-color: rgb(85, 141, 207);
	color: white;
}

#card-container {
	margin-bottom: -30px;
}

@media only screen and (min-width: 601px) {
  #form-contents {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
		margin-left: auto;
  }

  #payment-form {
    width: 75%;
  }
}

@media only screen and (min-width: 992px) {
	#card-button {
		margin-left: 42%;
	}

	#payment-form {
		width: 40%;
	  margin: 5px auto 0px auto;
	}
}
